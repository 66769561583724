export const environment = {
  appFrontendUrl: 'https://valuation.dev.profet.ai',
  backendBaseUrl: 'https://profet-backend.dev.profet.ai',
  portal: {
    backendBaseUrl: 'https://profet-portalbackend.dev.profet.ai',
    frontendBaseUrl: 'https://portal.dev.profet.ai',
  },
  logInUrl: 'https://portal.dev.profet.ai/login?redirect=https%3A%2F%2Fvaluation.dev.profet.ai',
  cognitoUserPoolId: 'us-west-2_2xQCylnqb',
  cognitoAppClientId: '20092l2v6s2vur2t8eobk5s9em',
  cognitoAuthDomain: 'profet-app-dev.auth.us-west-2.amazoncognito.com',
  cookieStorage: {
    // - Cookie domain (only required if cookieStorage is provided)
    domain: '.profet.ai',
    // (optional) - Cookie path
    path: '/',
    // (optional) - Cookie expiration in days
    expires: 1,
    // (optional) - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
    sameSite: 'strict',

    // (optional) - Cookie secure flag
    // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
    secure: true,
  },
  isLocal: false,
  googleMapsApiKey: 'AIzaSyCDKDlbBP3abIEcnV07uFA6ztj_MxzqLk4',
};
